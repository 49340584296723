import request from '@/utils/request'
import qs from "qs";

// 查询登录日志列表
export function list(query) {
  return request({
    url: 'logininfor/list',
    method: 'get',
    params: query
  })
}

// 删除登录日志
export function delLogininfor(infoIds) {
  return request({
    url: 'logininfor/delete',
    method: 'delete',
    data: qs.stringify(infoIds, { arrayFormat: 'comma' })
  })
}

// 清空登录日志
export function cleanLogininfor() {
  return request({
    url: 'logininfor/clean',
    method: 'delete'
  })
}
