<template>
    <div class="app-container menus">
        <el-form :model="queryParams" v-hasPermi="['monitor:logininfor:list']" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" style="width: 100%;height: 0.5%;text-align: left;">
            <el-form-item label="登录地址" prop="loginLocation">
                <el-input
                        v-model="queryParams.loginLocation"
                        placeholder="请输入登录地址"
                        clearable
                        size="small"
                        style="width: 240px;"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="操作人员" prop="loginName">
                <el-input
                        v-model="queryParams.loginName"
                        placeholder="请输入操作人员"
                        clearable
                        size="small"
                        style="width: 240px;"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select
                        v-model="queryParams.status"
                        placeholder="操作状态"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option
                            v-for="dict in sysCommonStatus"
                            :key="dict.id"
                            :label="dict.value"
                            :value="dict.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="操作时间">
                <el-date-picker
                        v-model="dateRange"
                        size="small"
                        style="width: 240px"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery" v-hasPermi="['monitor:logininfor:list']">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery" v-hasPermi="['monitor:logininfor:list']">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        :disabled="multiple"
                        @click="handleDelete"
                        v-hasPermi="['monitor:logininfor:remove']"
                >删除</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        @click="handleClean"
                        v-hasPermi="['monitor:logininfor:remove']"
                >清空</el-button>
            </el-col>
        </el-row>

        <el-table ref="tables" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="访问编号" align="center" prop="infoId" />
            <el-table-column label="用户名称" align="center" prop="userName" :show-overflow-tooltip="true" />
            <el-table-column label="登录地址" align="center" prop="ipaddr" width="130" :show-overflow-tooltip="true" />
            <el-table-column label="登录地点" align="center" prop="loginLocation" :show-overflow-tooltip="true" />
            <el-table-column label="浏览器" align="center" prop="browser" :show-overflow-tooltip="true" />
            <el-table-column label="操作系统" align="center" prop="os" :show-overflow-tooltip="true"/>
            <el-table-column label="登录状态" align="center" prop="status">
                <template slot-scope="scope">
                    <el-tag plain v-if="scope.row.status === '0'">成功</el-tag>
                    <el-tag type="danger" v-else>失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作信息" align="center" prop="msg" :show-overflow-tooltip="true" />
            <el-table-column label="登录日期" align="center" prop="loginTime"  width="180">
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
        />
    </div>
</template>

<script>
import { list,delLogininfor,cleanLogininfor} from '@/api/monitor/logininfor';

    export default {
        name: "logininfor",
        data() {
            return {
                // 总条数
                total: 0,
                // 表格数据
                list: [],
                // 搜索框是否显示
                showSearch: true,
                // 非多个禁用
                multiple: true,
                // 日期范围
                dateRange: [],
                // 状态
                sysCommonStatus:[{id:0,value: '成功'},{id: 1,value: '失败'}],
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    loginLocation: undefined,
                    loginName: undefined,
                    status: undefined,
                    beginTime:undefined,
                    endTime:undefined
                }
            }
        },
        created() {
          this.getList();
        },
        activated(){
            this.getList();
        },
        methods: {
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.dateRange = [];
                this.queryParams.beginTime = undefined;
                this.queryParams.endTime = undefined;
                this.resetForm("queryForm");
                // this.$refs.tables.sort(this.defaultSort.prop, this.defaultSort.order)
                this.handleQuery();
            },
            /** 查询登录日志列表 */
            getList() {
                const dataTime = this.dateRange;
                if (dataTime.length > 0){
                    this.queryParams.beginTime = dataTime[0];
                    this.queryParams.endTime = dataTime[1];
                }
                list(this.queryParams).then(res => {
                        this.list =res.data.data;
                        this.total = res.data.pageBean.count;
                    }
                );
            },
            // 多选框
            handleSelectionChange(val) {
                this.ids = val.map(item => item.infoId);
                this.multiple = !val.length;
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const infoIds = row.infoId || this.ids;
                this.$confirm('是否确认删除访问编号为"' + infoIds + '"的数据项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delLogininfor({infoIds}).then(res => {
                        if (res.status === 200 ){
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            handleClean(){
                this.$confirm('是否确认清空所有登录日志数据项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    cleanLogininfor().then(res => {
                        if (res.status === 200 ){
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '清除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        }
    }
</script>

<style scoped>
    /*main-container全局样式*/
    .app-container {
        padding: 20px;
    }
    .mb8 {
        margin: 0 -5px 8px;
    }

</style>